import React from 'react';
import { NextPage } from 'next';
import { getHome } from 'config/api';

import Hero from '@/components/Hero';
import Layout from '@/components/Layout';
import CallAction from '@/components/CallAction';
import { PageDataType } from 'models/types.interface';
import GridImages from '@/components/GridImages';
import getPageProps from 'config/getPageProps';

const Home: NextPage<PageDataType> = ({ data, seo, menuItems, footer, socials }) => {
  return (
    <>
      <Layout data={data} seo={seo} socials={socials} footer={footer} menuItems={menuItems}>
        <Hero data={data?.header} />
        <GridImages data={data?.gridImagem} />
        <CallAction data={data?.callAction} />
      </Layout>
    </>
  );
};

export default Home;

export async function getStaticProps() {
  const props = await getPageProps(getHome, 'homepage');

  return {
    props,
  };
}
