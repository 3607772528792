import React, { FC } from 'react';

import { CallActionContentType } from 'models/types.interface';

import styles from './CallAction.module.scss';
import Button from '../Button';

interface CallActionProps {
  data: CallActionContentType;
}

const CallAction: FC<CallActionProps> = ({ data }) => {
  return (
    <div className={styles.callAction}>
      <img
        className={`${styles.image} img-parallelX`}
        src={data?.background?.sourceUrl}
        alt={data?.background?.altText}
      />
      <img
        className={`${styles.image} ${styles.phone}`}
        src={data?.background?.sourceUrl}
        alt={data?.background?.altText}
      />
      {data?.textButton && <Button href={data?.linkButton}>{data?.textButton}</Button>}
    </div>
  );
};

export default CallAction;
