import { GridImagesContentType } from 'models/types.interface';
import React, { FC } from 'react';

import styles from './GridImages.module.scss';

interface GridImagesProps {
  data: GridImagesContentType;
}

const GridImages: FC<GridImagesProps> = ({ data }) => {
  const isColView = data?.featuredText || data?.firstText || data?.thirdText || data?.fourthText || data?.secondText;

  return (
    <div>
      <div
        className={`${styles.gridImages} ${data?.featuredImage?.sourceUrl ? '' : styles.noFeatured} ${
          isColView ? styles?.colView : ''
        }`}
      >
        <>
          <div className={styles.item}>
            {data?.firstText && (
              <div className={styles.overlay}>
                <h2 className="line-fade-in">{data?.firstText}</h2>
                {data?.firstDescription && <p className="line-fade-in">{data?.firstDescription}</p>}
              </div>
            )}
            <img className="img-zoom-out" src={data?.firstImage?.sourceUrl} alt="" />
          </div>
          {isColView && (
            <div className={styles?.info}>
              <h2 className="line-fade-in">{data?.firstText}</h2>
              {data?.firstDescription && <p className="line-fade-in">{data?.firstDescription}</p>}
            </div>
          )}
        </>
        <>
          <div className={styles.item}>
            {data?.secondText && (
              <div className={styles.overlay}>
                <h2 className="line-fade-in">{data?.secondText}</h2>
                {data?.secondDescription && <p className="line-fade-in">{data?.secondDescription}</p>}
              </div>
            )}
            <img className="img-zoom-out" src={data?.secondImage?.sourceUrl} alt="" />
          </div>
          {isColView && (
            <div className={styles?.info}>
              <h2 className="line-fade-in">{data?.secondText}</h2>
              {data?.secondDescription && <p className="line-fade-in">{data?.secondDescription}</p>}
            </div>
          )}
        </>
        <>
          <div className={styles.item}>
            {data?.thirdText && (
              <div className={styles.overlay}>
                <h2 className="line-fade-in">{data?.thirdText}</h2>
                {data?.thirdDescription && <p className="line-fade-in">{data?.thirdDescription}</p>}
              </div>
            )}
            <img className="img-zoom-out" src={data?.thirdImage?.sourceUrl} alt="" />
          </div>
          {isColView && (
            <div className={styles?.info}>
              <h2 className="line-fade-in">{data?.thirdText}</h2>
              {data?.thirdDescription && <p className="line-fade-in">{data?.thirdDescription}</p>}
            </div>
          )}
        </>
        <>
          <div className={styles.item}>
            {data?.fourthText && (
              <div className={styles.overlay}>
                <h2 className="line-fade-in">{data?.fourthText}</h2>
                {data?.fourthDescription && <p className="line-fade-in">{data?.fourthDescription}</p>}
              </div>
            )}

            <img className="img-zoom-out" src={data?.fourthImage?.sourceUrl} alt="" />
          </div>
          {isColView && (
            <div className={styles?.info}>
              <h2 className="line-fade-in">{data?.fourthText}</h2>
              {data?.fourthDescription && <p className="line-fade-in">{data?.fourthDescription}</p>}
            </div>
          )}
        </>
        {data?.featuredImage?.sourceUrl && (
          <>
            <div className={styles.item}>
              {data?.featuredText && (
                <div className={styles.overlay}>
                  <h2 className="line-fade-in">{data?.featuredText}</h2>
                  {data?.featuredDescription && <p className="line-fade-in">{data?.featuredDescription}</p>}
                </div>
              )}
              <img
                className="img-zoom-out"
                height={'100%'}
                width={'100%'}
                src={data?.featuredImage?.sourceUrl}
                alt={data?.featuredImage?.altText}
              />
            </div>
            {isColView && (
              <div className={styles?.info}>
                <h2 className="line-fade-in">{data?.featuredText}</h2>
                {data?.featuredDescription && <p className="line-fade-in">{data?.featuredDescription}</p>}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GridImages;
